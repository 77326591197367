import React, { useState } from "react"
import FontGeneratorWindow from "../../../componentForPages/ReactWindow/FontGenertor"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"

import {
  Title,
  SubTitle,
  Button,
  Textarea,
} from "../../../StyleComponents/styles"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "Fancy Font Generator",
    link: "/font-generator/",
  },
]
const seeAlsoArray = [
  "/straight-font-generator",
  "/text-emoticons-face-generator",
  "/emoticons-copy-and-paste",
  "/emoji-copy-and-paste",
]

function FancyFont(props) {
  const data = useStaticQuery(graphql`
    query {
      allFontJson {
        edges {
          node {
            font {
              A
              B
              C
              E
              F
              G
              H
              D
              I
              K
              J
              L
              M
              N
              O
              P
              Q
              R
              S
              T
              U
              V
              W
              X
              Y
              Z
              _0
              _1
              _2
              _3
              _4
              _5
              _6
              _7
              _8
              _9
              a
              b
              c
              d
              e
              f
              g
              h
              j
              i
              k
              l
              m
              n
              o
              p
              r
              s
              u
              q
              t
              v
              w
              x
              y
              z
            }
            emoctions
            moction
            emojisList
            grapgics
          }
        }
      }
    }
  `)
  let fontsList = data.allFontJson.edges[0].node

  let [inputValue, setinputValue] = useState("")
  let [result1, setresult1] = useState([])
  let [reactWindow, setReactWindow] = useState(false)

  function inputValueC(e) {
    let value = e.target.value
    setinputValue(value)
    randomizer(value)
    if (value === "") {
      setReactWindow(false)
    }
  }
  const clickRandomizer = () => {
    randomizer(inputValue)
  }

  const font = fontsList.font,
    emoctions = fontsList.emoctions,
    moction = fontsList.moction,
    emojisList = fontsList.emojisList,
    grapgics = fontsList.grapgics

  let keys = Object.keys(font)

  function randomizer(textInput) {
    if (textInput === "") {
    } else {
      let result = textInput.split("")
      let NamesData = []

      const everyLetterFont = () => {
        let outputResult
        let inputValueArray = []
        result.forEach(x => {
          let random = font[keys[(keys.length * Math.random()) << 0]]
          if (x.match(/\d+/g)) {
            inputValueArray.push(random[`_${x}`])
          } else if (x === " ") {
            inputValueArray.push(" ")
          } else if (font[keys[0]][x] === undefined) {
            inputValueArray.push(x)
          } else {
            inputValueArray.push(random[x])
          }
          outputResult = inputValueArray.join("")
        })
        return outputResult
      }
      const emoctionsString = () => {
        let random = Math.floor(Math.random() * emoctions.length)
        return emoctions[random]
      }
      const emojisListString = () => {
        let random = Math.floor(Math.random() * emojisList.length)
        return emojisList[random]
      }
      const moctionString = () => {
        let random = Math.floor(Math.random() * moction.length)
        return moction[random]
      }
      const grapgicsString = () => {
        let random = Math.floor(Math.random() * grapgics.length)
        return grapgics[random]
      }
      const regularFontSting = () => {
        let random = font[keys[(keys.length * Math.random()) << 0]]
        let inputValueArray = []
        let nameString
        result.forEach(x => {
          if (x.match(/\d+/g)) {
            inputValueArray.push(random[`_${x}`])
          } else if (x === " ") {
            inputValueArray.push(" ")
          } else if (font[keys[0]][x] === undefined) {
            inputValueArray.push(x)
          } else {
            inputValueArray.push(random[x])
          }
          return (nameString = inputValueArray.join(""))
        })
        return nameString
      }

      for (let i = 0; i < 120; i++) {
        if (i < 4) {
          NamesData.push(regularFontSting())
        } else if (i < 5) {
          NamesData.push(
            `${emoctionsString()} ${everyLetterFont()} ${emojisListString()}`
          )
        } else if (i < 7) {
          NamesData.push(everyLetterFont())
        } else if (i < 8) {
          NamesData.push(
            `${emojisListString()} ${everyLetterFont()} ${emoctionsString()}`
          )
        } else if (i < 12) {
          NamesData.push(everyLetterFont())
        } else if (i < 15) {
          NamesData.push(
            `${emoctionsString()} ${everyLetterFont()} ${moctionString()}`
          )
        } else if (i < 20) {
          NamesData.push(
            `${emoctionsString()} ${regularFontSting()} ${emoctionsString()}`
          )
        } else if (i < 30) {
          NamesData.push(
            `${moctionString()} ${everyLetterFont()} ${moctionString()}`
          )
        } else if (i < 45) {
          NamesData.push(
            `${emojisListString()} ${everyLetterFont()} ${emojisListString()}`
          )
        } else if (i < 60) {
          NamesData.push(`${moctionString()} ${everyLetterFont()}`)
        } else if (i < 75) {
          NamesData.push(`${everyLetterFont()} ${moctionString()}`)
        } else if (i < 85) {
          NamesData.push(`${emoctionsString()} ${everyLetterFont()}`)
        } else if (i < 95) {
          NamesData.push(
            `${emojisListString()} ${everyLetterFont()} ${moctionString()}`
          )
        } else if (i < 105) {
          NamesData.push(
            grapgicsString().replace("[[text]]", regularFontSting())
          )
        } else {
          NamesData.push(
            grapgicsString().replace("[[text]]", everyLetterFont())
          )
        }
      }
      if (reactWindow === false) {
        setReactWindow(true)
      }
      setresult1(NamesData)
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Font Generator Copy Paste (ﾉ◕ヮ◕)ﾉ*:･ﾟ✧ - Copy Paste Fancy Text"
        description="Fancy Font Generator ᕕ( ᐛ )ᕗ, Generate fancy ٩(˘◡˘)۶ text font by input the text and click on the Make Fonts button then copy and paste wherever you want, generate font for Instagram, Twitter, Facebook, and many others."
        keywords={[
          "fancy font generator, font generator, instagram font generator, fancy fonts, facebook font generator, twitter font generator, generate font copy paste, font text copy paste.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <section className="container">
        <Title>Fancy Font Generator</Title>
        <SubTitle>Random Fancy Text Generator</SubTitle>

        <Textarea
          name="fontGenerator"
          aria-label="fontGenerator"
          placeholder="Type or paste your text here :)"
          value={inputValue}
          onChange={inputValueC}
        ></Textarea>
        <Button
          className="mb-3"
          borderColor="#1da1f2"
          color="#1da1f2"
          hoverColor="white"
          hoverBorderColor="#1da1f2"
          hoverBackgroundColor="#1da1f2"
          value="Generate New Fonts"
          onClick={clickRandomizer}
        >
          Generate New Fonts
        </Button>
        {reactWindow ? <FontGeneratorWindow listItems={result1} /> : ""}
        <br />
        <p>Click again on the button above to generate new font.</p>
        <br />
        <h3>Random Font Generator</h3>
        <p>
          Generate fancy text font by input the text and click on the Make Fonts
          button then copy and paste wherever you want, generate font for
          Instagram, Twitter, Facebook, and many others.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
      </section>
      <br />
    </Layout>
  )
}

export default FancyFont
