import React from 'react';
import { FixedSizeList as List } from 'react-window';
import { ButtonSignTrue, BoxShadowWindow, Button, Input } from '../../StyleComponents/styles';

function FontGeneratorWindow({ listItems }) {
  function copyPasteString(e, copyString) {
    let btnElement = e.target.firstElementChild;
    let elem = document.createElement('textarea');
    document.body.appendChild(elem);
    elem.value = copyString;
    elem.select();
    let copied = document.execCommand('copy');
    document.body.removeChild(elem);
    if (copied) {
      btnElement.style.display = 'block';
      setTimeout(function () {
        btnElement.style.display = 'none';
      }, 1500);
    }
  }

  const Row = ({ index, style }) => {
    return (
      <div
        style={{
          ...style,
          display: 'flex',
          padding: '15px 15px 0 15px',
        }}
      >
        <Input
          type="text"
          border="0"
          fontSize="1.75rem"
          fontSizeMobile="1rem"
          className="makeTextCentered"
          value={listItems[index]}
          readOnly
        />
        <Button onClick={(e) => copyPasteString(e, listItems[index])}>
          Copy <ButtonSignTrue>&#10003;</ButtonSignTrue>
        </Button>
      </div>
    );
  };
  return (
    <BoxShadowWindow>
      <List height={800} itemCount={listItems.length} itemSize={75} width={'100%'}>
        {Row}
      </List>
    </BoxShadowWindow>
  );
}

export default FontGeneratorWindow;
